/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
    Card,
    Container,
    Row,
    Col,
} from "reactstrap";
let count = 0
const galleryCard = (url) => {
    let new_count = count
    count += 1
    return <Col lg="3" style={{ padding: '10px' }} key={new_count} >
        <img src={url} alt="likey_1" className="img-thumbnail rounded"></img>
    </Col>
}



export default function Gallery() {
    // Declare a new state variable, which we'll call "count"
    const [gallery, setGallery] = useState([
        "https://lh3.googleusercontent.com/4waK7Pg8XkLYJfO1tRlcpJoGWmcXij6mYMU2kx3yi75a2nHFBYbLSF76sQsA21pzngSKdOemO3CajjivMsC8SWMK4CIBLP7tq-gqyQ=w600",
        "https://lh3.googleusercontent.com/HU0eGrhgLmejzD5xfTENDdO1sdE8DtNvDFWg1SQfm2VjIB5gDMhIAkNeQfc1uxpebuZKpBSwNIojpw5668wPufrAcT_B3inWkYwksA=w600",
        "https://lh3.googleusercontent.com/ilpXdaf77ateZ6bF-bAV_pUAszEBVVIU_Ti6l6am9pVcmp-HmHcrx3P5h_7neQp5lBHSwHk6C07WS2YgXCBuMYVbElBD1lnIrgmc=s0",
        "https://lh3.googleusercontent.com/D2ZIFFj-qVBfpIyFrjQM9YZ-DozxQ91KAvTCgURgX6LBHmOOT_YRYj01HrwfOiS1U53kKvLpb4Nq0nysj2W5CSUUL5X369_lX9l3=s0",
        "https://lh3.googleusercontent.com/Sz7RnBCtqQeElu-5qeGxrKoF4DKs3F-Vla7q0CLv4VHM_8t7ND-MsfO5u26B-bvX1461ZT2yBakuLyAFvK23m_q6ZvQvSpDyr1LCGw=s0",
        "https://lh3.googleusercontent.com/7pggSe4nQz86SAbaAxdrcb2OyZndkW5HD9A1rrjhyEQr22v76bbHBfHO2s3HyTp7KkeKc3b7rq6KJOSZnQNK8HfbcgG4ZPQoYYgTZw=s0",
        "https://lh3.googleusercontent.com/IFgOx9bElwCim7NxqF7vwejhVYTJvEQB0DYgMlz7Rhqm4AA--ChdC76Ta9wbpSEy-pf0fgdAlj8avBwh8Cv6q1zZvCDOSGRFTg6KBg=s0",
        "https://lh3.googleusercontent.com/v1MH2ur1YihNFzx9bqwjVG2rCEY07gidikV5kgmh2he-CgCQ9L42DqQ9ntuO7tvU3AIec1wWjMnB5l3iXEQDb8Y3VI03xuK8_Btj=s0",
    ]);



    return (
        <>
            <div className="cd-section" id="blogs">
                <div className="section " id="blogs-4">
                    <Container >
                        {
                            Array(Math.ceil(gallery.length / 4)).fill().map(
                                (_, row_index) => {
                                    return (<Row style={{ padding: '10px' }}>
                                        {
                                            Array(Math.ceil(4)).fill().map((_, index) => {
                                                console.log((row_index * 4) + index)
                                                console.log(gallery[(row_index * 4) + index])
                                                return galleryCard(gallery[(row_index * 4) + index])
                                            }
                                            )
                                        }
                                    </Row>)
                                }
                            )
                        }
                    </Container>
                </div>
            </div>{" "}
        </>
    );
}
