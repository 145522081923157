/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

export default function Team() {
    return (
        <>
            <div className="cd-section" id="teams">

                <div className="team-5">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title text-primary" >Team</h2>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="4">
                                <Card className="card-profile">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/30.png").default}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <hr className="line-primary" />
                                        <h3 className="job-title">0x-mikey.eth</h3>
                                        <Table className="tablesorter" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-atom" />
                                                        Skills
                                                    </td>
                                                    <td className="text-right">Developer</td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-user-run" />
                                                        Hobbies
                                                    </td>
                                                    <td className="text-right">Gaming</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-chart-bar-32" />
                                                        Level
                                                    </td>
                                                    <td className="text-right">• • • • • •</td>
                                                </tr> */}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/31.png").default}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <hr className="line-primary" />
                                        <h3 className="job-title">MartiePie</h3>
                                        <Table className="tablesorter" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-atom" />
                                                        Skills
                                                    </td>
                                                    <td className="text-right">Leadership & Artist </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-user-run" />
                                                        Hobbies
                                                    </td>
                                                    <td className="text-right">Proffessional TickToker</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-chart-bar-32" />
                                                        Level
                                                    </td>
                                                    <td className="text-right">• • • • •</td>
                                                </tr> */}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img img-raised"
                                                src={require("assets/img/32.png").default}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <hr className="line-primary" />
                                        <h3 className="job-title">Developer</h3>
                                        <Table className="tablesorter" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-atom" />
                                                        Skills
                                                    </td>
                                                    <td className="text-right">Developer, Artist & Visionary</td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-user-run" />
                                                        Hobbies
                                                    </td>
                                                    <td className="text-right">Gaming</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td className="text-left">
                                                        <i className="tim-icons icon-chart-bar-32" />
                                                        Level
                                                    </td>
                                                    <td className="text-right">• • • •</td>
                                                </tr> */}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>{" "}
        </>
    );
}
