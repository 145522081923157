/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function Features() {
  return (
    <>
      <div className="cd-section" id="tables">
        {/* ********* Table 2 ********* */}
        <div className="tables-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-4 text-primary" >THE WORLD OF LIKEYS</h2>
              </Col>
            </Row>

            <Row>
              <Col md='6' className="ml-auto mr-auto text-center">
                <Card className="card-coin card-plain" style={{ padding: "25px" }}>
                  <CardBody>
                    <Table  >
                      <tbody>
                        <tr>
                          <td className="text-left">BACKGROUNDS</td>
                          <td className="text-right">119</td>
                        </tr>
                        <tr>
                          <td className="text-left">BODIES</td>
                          <td className="text-right">37</td>
                        </tr>
                        <tr>
                          <td className="text-left">CLOTHES</td>
                          <td className="text-right">15</td>
                        </tr>
                        <tr>
                          <td className="text-left">EARRINGS</td>
                          <td className="text-right">6</td>
                        </tr>
                        <tr>
                          <td className="text-left">EYES</td>
                          <td className="text-right">21</td>
                        </tr>
                        <tr>
                          <td className="text-left">HAIR/HATS</td>
                          <td className="text-right">136</td>
                        </tr>
                        <tr>
                          <td className="text-left">MOUTHS</td>
                          <td className="text-right">9</td>
                        </tr>
                        <tr>
                          <td className="text-left">LIKEYS</td>
                          <td className="text-right">777</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END Table 2 ********* */}
      </div>{" "}
    </>
  );
}
