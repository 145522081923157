/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

export default function Footer() {
    const scrollPage = (e, id) => {
        e.preventDefault();
        if (document.getElementById(id) !== null) {
            document.getElementById(id).scrollIntoView();
        }
    };
    return (
        <>
            {/* ********* SIMPLE FOOTER ********* */}
            <footer className="footer footer-simple">
                <Container>
                    <nav>
                        <ul>
                            <li>
                                <NavLink
                                    onClick={(e) => scrollPage(e, "teams")}
                                    
                                >
                                    TEAM
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={(e) => scrollPage(e, "tables")}
                                    target="_blank"
                                    className="ml-1"
                                >
                                    RARITY
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                     onClick={(e) => scrollPage(e, "accordian")}
                                    target="_blank"
                                    className="ml-1"
                                >
                                    FAQ
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    href="https://www.google.com"
                                    target="_blank"
                                    className="ml-1"
                                >
                                    License
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <div className="copyright">
                        © {new Date().getFullYear()}, Designed by{" "}
                        <a
                            className="copyright-link"
                            href="https://www.google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Company
                        </a>
                        . Coded by{" "}
                        <a
                            className="copyright-link"
                            href="https://www.google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Pepe
                        </a>
                        .
                    </div>
                </Container>
            </footer>
            {/* ********* END SIMPLE FOOTER ********* */}
        </>
    );
}
